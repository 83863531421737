/* nunito-200normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito Extra Light '),
    local('Nunito-Extra Light'),
    url('./files/nunito-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* nunito-200italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito Extra Light italic'),
    local('Nunito-Extra Lightitalic'),
    url('./files/nunito-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-200italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-300normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Light '),
    local('Nunito-Light'),
    url('./files/nunito-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* nunito-300italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Light italic'),
    local('Nunito-Lightitalic'),
    url('./files/nunito-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-400normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Regular '),
    local('Nunito-Regular'),
    url('./files/nunito-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* nunito-400italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Regular italic'),
    local('Nunito-Regularitalic'),
    url('./files/nunito-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-600normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito SemiBold '),
    local('Nunito-SemiBold'),
    url('./files/nunito-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* nunito-600italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito SemiBold italic'),
    local('Nunito-SemiBolditalic'),
    url('./files/nunito-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-700normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Bold '),
    local('Nunito-Bold'),
    url('./files/nunito-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* nunito-700italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Bold italic'),
    local('Nunito-Bolditalic'),
    url('./files/nunito-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-800normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito ExtraBold '),
    local('Nunito-ExtraBold'),
    url('./files/nunito-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* nunito-800italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito ExtraBold italic'),
    local('Nunito-ExtraBolditalic'),
    url('./files/nunito-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-900normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Black '),
    local('Nunito-Black'),
    url('./files/nunito-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* nunito-900italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Black italic'),
    local('Nunito-Blackitalic'),
    url('./files/nunito-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

